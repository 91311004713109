import React from 'react'
import { Radio, Select, Button, Divider, Row, Col, Input, message, Icon, Alert, Table, Steps, Tooltip, Modal, Drawer } from 'antd'
import { connect } from 'react-redux'
import psl from 'psl'
import ipRegex from 'ip-regex'
import Clipboard from 'react-clipboard.js'
import { CERTIFICATE_TYPE, CERTIFICATE_AUTH_MODE, CERTIFICATE_VERSION_STATUS, CERTIFICATE_BRAND, ERRORS, ACME_CHALLENGE_TYPE } from '../../utils/constant'
import { getDnsProviders } from '../../redux/dns/provider'
import { createCertificate, createCertificateOrder } from '../../redux/certificate'
import { getCertificateVersionDetail, createCertificateVersion } from '../../redux/certificate/version'
import { getDnsServers, verifyDnsServer } from '../../redux/dns/server'
import { checkDnsCname, checkHttpFile, checkHttpProxy } from '../../redux/common'
import ConfigField from '../../components/ConfigField'
import { maskEmail } from '../../utils'
import sectigoImg from '../../assets/svg/sectigo.svg'
import letsenryptImg from '../../assets/svg/letsencrypt.svg'
import CreateCertificateVersionModal from '../../components/CreateCertificateVersionModal'
import CertificatePrice from '../../components/CertificatePrice'
import Charge from '../../components/Charge'
import HttpAuthModeServerProxySettings from '../../components/HttpAuthModeServerProxySettings'
import HttpManAuthModeServerFileSettings from '../../components/HttpManAuthModeServerFileSettings'
import CertificateVersionDetail from '../../components/CertificateVersionDetail'
import { calculateCertificatePrice } from '../../utils/price'

class CreateCertificate extends React.Component {
  constructor(props) {
    super(props)

    const urlObj = new URL(window.location.href)
    const domain = urlObj.searchParams.get('domain')

    let authMode = CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE
    let certificateType = CERTIFICATE_TYPE.SINGLE
    let certificateBrand = CERTIFICATE_BRAND.SECTIGO
    let domains = []
    if (domain) {
      certificateBrand = CERTIFICATE_BRAND.LETSENCRYPT
      if (domain.indexOf('*') > 0) {
        certificateType = CERTIFICATE_TYPE.WILDCARD
      }

      if (ipRegex({exact: true}).test(domain)) {
        certificateType = CERTIFICATE_TYPE.IP
        certificateBrand = CERTIFICATE_BRAND.SECTIGO
        authMode = CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE
      }
      domains = [domain]
    }

    this.state = {
      authMode,
      certificateType,
      certificateBrand,
      domains,
      dnsProviderId: null,
      dnsServerId: null,
      dnsServerAccessJson: null,
      serverVerified: false,
      serverVerifying: false,
      timerSeconds: -1,
      certificateDetail: null,
      certificateVersion: null,
      certificateVersionSeconds: -1,
      currentStep: 0,
      createCertificateVersionModalVisible: false,
      chargeDrawerVisible: false
    }
    this.props.dispatch(getDnsProviders())
    this.props.dispatch(getDnsServers({ offset: 0, limit: 1000000 }))
  }

  get dnsProviderId() {
    const { dnsProviders, dnsServers } = this.props
    const { dnsProviderId } = this.state
    if (dnsProviderId) {
      return dnsProviderId
    } else {
      if (dnsServers.count) {
        return dnsServers.rows[0].dnsProviderId
      }
      if (dnsProviders.length) {
        return dnsProviders[0].id
      }
    }
    return null
  }

  get isDebugMode() {
    return window.location.href.includes('debug=true')
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.btnSecondsTimer) {
      clearInterval(this.btnSecondsTimer)
      this.btnSecondsTimer = null
    }
    if (this.checkDnsServerTimer) {
      clearInterval(this.checkDnsServerTimer)
      this.checkDnsServerTimer = null
    }
    if (this.certificateSecondsTimer) {
      clearInterval(this.certificateSecondsTimer)
      this.certificateSecondsTimer = null
    }
    if (this.getCertificateTimer) {
      clearInterval(this.getCertificateTimer)
      this.getCertificateTimer = null
    }
  }

  getStepStatus = ({stepIndex}) => {
    const currentStep = this.state.currentStep
    if (stepIndex > currentStep) {
      return 'wait'
    } else if (stepIndex === currentStep) {
      return 'process'
    } else {
      return 'finish'
    }
  }

  handleChangeCertificateType = (e) => {
    const certificateType = e.target.value
    this.setState({
      certificateType
    })

    if (certificateType === CERTIFICATE_TYPE.IP && this.state.authMode !== CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE && this.state.authMode !== CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) {
      this.setState({
        authMode: CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE
      })
    }
  }

  handleChangeCertificateBrand = (e) => {
    const certificateBrand = e.target.value
    this.setState({
      certificateBrand
    })

    if (this.state.certificateType === CERTIFICATE_TYPE.IP) {
      if (certificateBrand === CERTIFICATE_BRAND.LETSENCRYPT) {
        this.setState({
          certificateType: CERTIFICATE_TYPE.WILDCARD
        })
      }
    }
  }

  handleChangeAuthMode = (e) => {
    const authMode = e.target.value
    const {domains, certificateType, certificateBrand} = this.state
    const hasWildcardDomain = domains.some(item => item.startsWith('*.'))
    if (hasWildcardDomain) {
      if (authMode === CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE) {
        return message.error('HTTP代理验证模式不支持申请泛域名类型证书，请选择其它域名所有权验证方式')
      }
      if (authMode === CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) {
        return message.error('HTTP文件验证模式不支持申请泛域名类型证书，请选择其它域名所有权验证方式')
      }
    }

    if (certificateType === CERTIFICATE_TYPE.IP) {
      if (authMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) {
        return message.error('免DNS授权验证模式不支持申请IP证书，请选择其它IP所有权验证方式')
      }
      if (authMode === CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE) {
        return message.error('DNS授权验证模式不支持申请IP证书，请选择其它IP所有权验证方式')
      }
    }

    if (certificateBrand === CERTIFICATE_BRAND.LETSENCRYPT) {
      if (authMode === CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) {
        return message.error("Let'sencrypt品牌证书暂不支持HTTP文件验证模式")
      }
    }

    this.setState({
      authMode: e.target.value,
      serverVerified: false,
      serverVerifying: false,
      timerSeconds: -1,
    })
    clearInterval(this.checkDnsServerTimer)
    clearInterval(this.btnSecondsTimer)

    this.props.dispatch(createCertificateOrder({type: certificateType, brand: certificateBrand, domains, authMode}))
  }

  handleChangeDomains = (e) => {
    const domains = e.target.value ? e.target.value.split('\n').map(item => item.trim()) : []
    this.setState({
      domains,
      serverVerified: false,
      serverVerifying: false,
      timerSeconds: -1,
    })
    clearInterval(this.checkDnsServerTimer)
    clearInterval(this.btnSecondsTimer)
  }

  handleChangeDnsProvider = (e) => {
    if (e.target.value === -1) {
      return
    }

    this.setState({
      dnsServerId: null,
      dnsServerAccessJson: null,
      serverVerified: false,
      serverVerifying: false,
      timerSeconds: -1,
      dnsProviderId: e.target.value
    })
    clearInterval(this.checkDnsServerTimer)
    clearInterval(this.btnSecondsTimer)
  }

  handleChangeDnsServer = (value) => {
    this.setState({
      dnsServerId: value,
      serverVerified: false,
      serverVerifying: false,
      timerSeconds: -1,
      dnsServerAccessJson: null
    })
    clearInterval(this.checkDnsServerTimer)
    clearInterval(this.btnSecondsTimer)
  }

  handleEditDnsServer = (field, value) => {
    const accessJson = { ...(this.state.dnsServerAccessJson || {}) }
    accessJson[field] = value
    this.setState({
      dnsServerAccessJson: accessJson,
      serverVerified: false,
      serverVerifying: false,
      timerSeconds: -1
    })
    clearInterval(this.checkDnsServerTimer)
    clearInterval(this.btnSecondsTimer)
  }

  handleShowChargeDrawer = () => {
    this.setState({
      chargeDrawerVisible: true
    })
  }

  handleChargeDrawerClose = () => {
    this.setState({
      chargeDrawerVisible: false
    })
  }

  checkDomains = () => {
    const {certificateType, certificateBrand, domains} = this.state
    if (!certificateBrand) {
      return {error: '请选择证书品牌'}
    }

    if (!domains) {
      return {error: '请先输入域名'}
    }

    const validDomains = domains.filter(item => item && item.length).map(item => item.trim())
    if (!validDomains.length) {
      return {error: '请先输入域名'}
    }

    if (certificateType === CERTIFICATE_TYPE.SINGLE && validDomains.length > 1) {
      return {error: '单域名类型证书域名数量不能多于1个'}
    }

    if (certificateType === CERTIFICATE_TYPE.WILDCARD && validDomains.length > 1) {
      return {error: '泛域名类型证书域名数量不能多于1个'}
    }

    if (certificateType === CERTIFICATE_TYPE.MULTI && validDomains.length <= 1) {
      return {error: '多域名类型证书域名数量不能少于2个'}
    }

    if (certificateType === CERTIFICATE_TYPE.MULTI && validDomains.length > 100) {
      return {error: '多域名类型证书域名数量不能多于100个'}
    }

    if (certificateType === CERTIFICATE_TYPE.WILDCARD && !validDomains.every(item => item.startsWith('*.'))) {
      return {error: '泛域名证书中域名必须以"*."开头'}
    }

    if (certificateType === CERTIFICATE_TYPE.SINGLE && validDomains.some(item => item.startsWith('*.'))) {
      return {error: '单域名证书中域名不能以"*."开头'}
    }

    if (certificateType !== CERTIFICATE_TYPE.IP) {
      for (const domain of validDomains) {
        const domainUnified = domain.replace('*.', '')
        if (!psl.isValid(domainUnified)) {
          return {error: `域名【${domainUnified}】不是合法域名`}
        }
      }
    } else {
      for (const domain of validDomains) {
        if (!ipRegex({exact: true}).test(domain)) {
          return {error: `IP【${domain}】不是合法IP地址`}
        }
      }
    }

    return {error: null, validDomains}
  }

  handleVerifyDnsServer = () => {
    const { dnsServerId, dnsServerAccessJson, certificateType } = this.state
    const dnsProviderId = this.dnsProviderId

    const {error, validDomains} = this.checkDomains()
    if (error) {
      return message.error(error)
    }

    if (!dnsServerId && !dnsServerAccessJson) {
      return message.error('您还未选择或输入DNS授权')
    }

    message.warn('DNS授权验证所需时长与域名数量正相关，请耐心等待')

    this.props.dispatch(verifyDnsServer({ dnsServerId, dnsProviderId, accessJson: dnsServerAccessJson, certificateType, domains: validDomains }))
    this.setState({
      serverVerified: false,
      serverVerifying: true,
      timerSeconds: 30
    })

    this.btnSecondsTimer = setInterval(() => {
      this.setState({
        timerSeconds: this.state.timerSeconds >= 0 ? this.state.timerSeconds - 1 : this.state.timerSeconds
      })
    }, 1000)

    this.checkDnsServerTimer = setInterval(() => {
      this.props.dispatch(verifyDnsServer({ dnsServerId, dnsProviderId, accessJson: dnsServerAccessJson, certificateType, domains: validDomains })).then(response => {
        if (response.errorMsg) {
          clearInterval(this.checkDnsServerTimer)
          clearInterval(this.btnSecondsTimer)
          this.setState({
            serverVerifying: false,
            serverVerified: false,
            timerSeconds: -1
          })
          return message.error(response.errorMsg)
        }
        const result = response.payload
        if (!result.checking) {
          clearInterval(this.checkDnsServerTimer)
          clearInterval(this.btnSecondsTimer)
          if (!result.success) {
            this.setState({
              serverVerifying: false,
              serverVerified: false,
              timerSeconds: -1
            })
            return message.error(`DNS授权验证不通过${result.message ? `:${result.message}` : ''}`)
          } else {
            this.setState({
              serverVerifying: false,
              serverVerified: true,
              timerSeconds: -1
            })
            return message.success('DNS授权验证通过')
          }
        }
      })
    }, 3000)
  }

  handleCheckDnsCname = () => {
    const { certificateType, certificateBrand } = this.state
    const { certificateThirdOrder } = this.props.certificate

    const {error, validDomains} = this.checkDomains()
    if (error) {
      return message.error(error)
    }

    this.setState({
      serverVerified: false,
      serverVerifying: true
    })

    const certificateThirdOrderId = certificateThirdOrder ? certificateThirdOrder.id : undefined
    this.props.dispatch(checkDnsCname({ certificateType, certificateBrand, domains: validDomains, certificateThirdOrderId })).then((response) => {
      if (response.errorMsg) {
        this.setState({
          serverVerifying: false,
          serverVerified: false
        })
        return message.error(response.errorMsg)
      }

      if (response.payload.checked) {
        this.setState({
          serverVerifying: false,
          serverVerified: true
        })
        return message.success('验证解析记录添加成功')
      } else {
        this.setState({
          serverVerifying: false,
          serverVerified: false
        })
        return message.error('验证解析记录未添加成功，如您已正确添加，请等待域名解析记录生效后重试')
      }
    })
  }

  handleCheckHttpProxy = () => {
    const { certificateType } = this.state

    const {error, validDomains} = this.checkDomains()
    if (error) {
      return message.error(error)
    }

    this.setState({
      serverVerified: false,
      serverVerifying: true
    })

    this.props.dispatch(checkHttpProxy({ certificateType, domains: validDomains })).then((response) => {
      if (response.errorMsg) {
        this.setState({
          serverVerifying: false,
          serverVerified: false
        })
        return message.error(response.errorMsg)
      }

      if (response.payload.checked) {
        this.setState({
          serverVerifying: false,
          serverVerified: true
        })
        return message.success('验证服务端配置成功')
      } else {
        this.setState({
          serverVerifying: false,
          serverVerified: false
        })
        return message.error('验证服务端配置失败，请确认配置生效后重试')
      }
    })
  }

  handleCheckHttpFile = () => {
    const { certificateThirdOrder } = this.props.certificate

    this.setState({
      serverVerified: false,
      serverVerifying: true
    })

    const certificateThirdOrderId = certificateThirdOrder ? certificateThirdOrder.id : undefined
    this.props.dispatch(checkHttpFile({ certificateThirdOrderId })).then((response) => {
      if (response.errorMsg) {
        this.setState({
          serverVerifying: false,
          serverVerified: false
        })
        return message.error(response.errorMsg)
      }

      if (response.payload.checked) {
        this.setState({
          serverVerifying: false,
          serverVerified: true
        })
        return message.success('验证服务端文件成功')
      } else {
        this.setState({
          serverVerifying: false,
          serverVerified: false
        })
        return message.error('验证服务端文件失败，请确认服务端文件创建成功后重试')
      }
    })
  }

  handleCopyAliasDomainRecordSuccess = () => {
    message.success('解析记录已成功复制到剪贴板')
  }

  handleCopyAliasDomainRecordValueSuccess = () => {
    message.success('解析记录值已成功复制到剪贴板')
  }

  handleCompleteAddDomains = () => {
    const {error, validDomains} = this.checkDomains()
    if (error) {
      return message.error(error)
    }
  
    this.setState({
      currentStep: 1,
      certificateDetail: null,
      certificateVersion: null,
      domains: validDomains
    })

    const {certificateType, certificateBrand, authMode} = this.state
    if (certificateType && validDomains && validDomains.length) {
      this.props.dispatch(createCertificateOrder({type: certificateType, brand: certificateBrand, domains: validDomains, authMode}))
    }

    const hasWildcardDomain = validDomains.some(item => item.startsWith('*.'))
    if (hasWildcardDomain && (authMode === CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE || authMode === CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE)) {
      this.setState({
        authMode: CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE
      })
    }
  }

  handleBackToAddDomains = () => {
    this.setState({
      currentStep: 0
    })
  }

  handleCreateCertificate = () => {
    const { certificateType: type, certificateBrand, authMode, dnsServerId, dnsServerAccessJson, serverVerified, serverVerifying } = this.state
    const { certificateThirdOrder } = this.props.certificate
    const { certificatePrice: certificatePriceConfig } = this.props.configs
    const dnsProviderId = this.dnsProviderId
  
    const {error, validDomains} = this.checkDomains()
    if (error) {
      return message.error(error)
    }

    if (authMode === CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE) {
      if (!dnsServerId && !dnsServerAccessJson) {
        return message.error('请选择或输入DNS授权')
      }
    }

    if (serverVerifying) {
      switch (authMode) {
        case CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE:
          return message.error('请等待解析记录验证完成')
        case CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE:
          return message.error('请等待DNS授权验证完成')
        case CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE:
          return message.error('请等待服务端配置验证完成')
        case CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE:
          return message.error('请等待服务端文件验证完成')
        default:
          return
      }
    }

    if (!serverVerified) {
      switch (authMode) {
        case CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE:
          return message.error('请验证解析记录是否添加成功并生效')
        case CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE:
          return message.error('请验证DNS授权')
        case CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE:
          return message.error('请验证服务端配置')
        case CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE:
          return message.error('请验证服务端文件')
        default:
          return
      }
    }

    const submit = () => {
      const thirdOrderId = certificateThirdOrder ? certificateThirdOrder.id : undefined
      this.props.dispatch(createCertificate({ type, brand: certificateBrand, authMode, domains: validDomains, thirdOrderId, dnsServerId, dnsProviderId, dnsServerAccessJson })).then((response) => {
        if (response.errorMsg) {
          if (response.errorCode === ERRORS.CM0008) {
            return Modal.confirm({
              title: '账户余额不足，是否前往充值?',
              okText: <>前往充值<span style={{transform: 'scale(0.75)', transformOrigin: 'left', padding: '0px 5px', marginLeft: '3px', backgroundColor: '#f50'}}>最高优惠20%</span></>,
              okButtonProps: {style: {paddingRight: '0px'}},
              onOk: () => {
                this.handleShowChargeDrawer()
              }
            });
          }
  
          return message.error(response.errorMsg)
        }
        message.success('证书创建成功')

        const certificate = response.payload
        const certificateId = certificate.id
        const certificateVersionId = certificate.lastCertificateVersionId
      
        this.setState({
          currentStep: 2,
          certificateId,
          certificateDetail: certificate
        })
  
        this.refreshCertificateVersion({certificateId, certificateVersionId})
      })
    }

    const certificatePrice = calculateCertificatePrice({
      certificateBrand,
      certificateType: type,
      certificateDomains: validDomains,
      certificatePriceConfig 
    })

    if (certificatePrice > 0) {
      return Modal.confirm({
        title: '确定要创建证书吗？',
        content: <>证书费用(1RMB = 100 OHTTPS余额)：<span style={{fontWeight: '600', marginLeft: '3px', color: '#f50'}}>{certificatePrice/100}</span>&nbsp;RMB</>,
        maskClosable: true,
        onOk: submit,
      })
    } else {
      submit()
    }
  }

  handleCreateCertifiateVersion = () => {
    const {certificateBrand, authMode, certificateId} = this.state
    // Sectigo的证书使用免授权模式时更新需要重新设置CNAME解析并验证
    if (certificateBrand === CERTIFICATE_BRAND.SECTIGO && (authMode === CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE || authMode === CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) ) {
      this.setState({
        createCertificateVersionModalVisible: true
      })
      return
    }

    this.props.dispatch(createCertificateVersion({ certificateId, isForRetry: true })).then((response) => {
      if (response.errorMsg) {
        return message.error(response.errorMsg)
      }
      message.success('开始重新申请证书')

      const certificateVersion = response.payload
      const certificateVersionId = certificateVersion.id
      this.setState({
        certificateVersion
      })
      this.refreshCertificateVersion({certificateId, certificateVersionId})
    })
  }

  handelCreateCertificateVersionOk = (certificateVersion) => {
    const {certificateId} = this.state
    const certificateVersionId = certificateVersion.id
    this.setState({
      certificateVersion,
      createCertificateVersionModalVisible: false
    })
    this.refreshCertificateVersion({certificateId, certificateVersionId})
  }

  handelCreateCertificateVersionCancel = () => {
    this.setState({
      createCertificateVersionModalVisible: false
    })
  }

  refreshCertificateVersion = ({certificateId, certificateVersionId}) => {
    this.setState({
      timerSeconds: 60 * 15
    })

    this.certificateSecondsTimer = setInterval(() => {
      this.setState({
        timerSeconds: this.state.timerSeconds >= 0 ? this.state.timerSeconds - 1 : this.state.timerSeconds
      })
    }, 1000)

    this.getCertificateTimer = setInterval(() => {
      this.props.dispatch(getCertificateVersionDetail({ certificateId, certificateVersionId })).then(responseIn => {
        if (responseIn.errorMsg) {
          clearInterval(this.getCertificateTimer)
          clearInterval(this.certificateSecondsTimer)
          this.setState({
            timerSeconds: -1
          })
          return message.error(responseIn.errorMsg)
        }
        const certificateVersion = responseIn.payload
        this.setState({
          certificateVersion
        })
        if (certificateVersion.status !== CERTIFICATE_VERSION_STATUS.GENERATING && certificateVersion.status !== CERTIFICATE_VERSION_STATUS.QUEUING) {
          clearInterval(this.getCertificateTimer)
          clearInterval(this.certificateSecondsTimer)
          this.setState({
            timerSeconds: -1
          })
          if (certificateVersion.status === CERTIFICATE_VERSION_STATUS.ERROR) {
            return message.error(certificateVersion.error ? `证书申请失败:${certificateVersion.error}` : '证书申请失败')
          } else {
            return message.success('证书申请成功')
          }
        }
      })
    }, 3000)
  }

  handleGoMonitorCertificates = () => {
    this.props.history.push(`/monitor/certificates`)
  }

  handleGoMonitorDashboard = () => {
    this.props.history.push(`/monitor/dashboard`)
  }

  renderDnsServer = () => {
    const { dnsProviders, dnsServers } = this.props
    const { dnsServerId, dnsServerAccessJson, serverVerified } = this.state
    const dnsProviderId = this.dnsProviderId

    const dnsProvider = dnsProviders.find(item => item.id === dnsProviderId)
    const { fields } = dnsProvider.configJson
    if (!dnsServerId) {
      return (
        <>
          {fields.map((item, index) => {
            const value = (dnsServerAccessJson || {})[item.field]
            return (
              <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                <Col span={4}>
                  {item.name}
                </Col>
                <Col span={17}>
                  <ConfigField {...item} value={value} onChange={this.handleEditDnsServer} editable/>
                </Col>
                <Col>
                  {serverVerified ? <Icon type="check-circle" style={{ color: '#52c41a' }} /> : ''}
                </Col>
              </Row>
            )
          })}
        </>
      )
    } else {
      const dnsServer = dnsServers.rows.find(item => item.id === dnsServerId)
      return (
        <>
          {fields.map((item, index) => {
            const value = dnsServer.accessJson[item.field]
            return (
              <Row gutter={16} style={{ marginTop: '10px' }} type='flex' align='middle' key={index}>
                <Col span={4}>
                  {item.name}
                </Col>
                <Col span={17}>
                  <ConfigField {...item} value={value} disabled/>
                </Col>
                <Col>
                  {serverVerified ? <Icon type="check-circle" style={{ color: '#52c41a' }} /> : ''}
                </Col>
              </Row>
            )
          })}
        </>
      )
    }
  }

  renderCertificatesInput = () => {
    const { certificateType, certificateBrand, domains } = this.state

    switch (certificateType) {
      case CERTIFICATE_TYPE.SINGLE:
        return <Input.TextArea rows={8} placeholder='请输入域名，不能以*.开头，只支持一个，例如：&#13;&#10;www.ohttps.com' allowClear value={domains.join('\n')} onChange={this.handleChangeDomains} />
      case CERTIFICATE_TYPE.MULTI:
        if (certificateBrand === CERTIFICATE_BRAND.LETSENCRYPT) {
          return <Input.TextArea rows={8} placeholder='请输入域名，每行一个，支持泛域名和单域名混合，可以包含多个主域名，例如：&#13;&#10;*.ohttps.com&#13;&#10;*.google.com' allowClear value={domains.join('\n')} onChange={this.handleChangeDomains} />
        } else {
          return <Input.TextArea rows={8} placeholder='请输入域名，每行一个，支持泛域名和单域名混合，可以包含多个主域名，例如：&#13;&#10;*.ohttps.com&#13;&#10;*.google.com&#13;&#10;注意：对于Sectigo品牌的多域名证书，泛域名不包含主域名，例如：*.ohttps.com不包含ohttps.com，如需证书中包含ohttps.com则需要单独添加，例如：&#13;&#10;*.ohttps.com&#13;&#10;ohttps.com' allowClear value={domains.join('\n')} onChange={this.handleChangeDomains} />
        }
      case CERTIFICATE_TYPE.WILDCARD:
        return <Input.TextArea rows={8} placeholder='请输入域名，必须以*.开头，只支持一个，例如：&#13;&#10;*.ohttps.com' allowClear value={domains.join('\n')} onChange={this.handleChangeDomains} />
      case CERTIFICATE_TYPE.IP:
        return <Input.TextArea rows={8} placeholder='请输入IP，每行一个，支持多个，例如：&#13;&#10;192.168.0.1&#13;&#10;192.168.0.2&#13;&#10;192.168.0.3' allowClear value={domains.join('\n')} onChange={this.handleChangeDomains} />
      default:
        return
    }
  }

  renderDnsApiAuthMode = () => {
    const { dnsProviders, dnsServers } = this.props
    if (!dnsProviders.length) {
      return <></>
    }

    const { dnsServerId, serverVerifying, timerSeconds } = this.state
    const dnsProviderId = this.dnsProviderId
    const dnsProvider = dnsProviders.find(item => item.id === dnsProviderId)

    const { userId, email } = this.props.me

    return (
      <div style={{ marginTop: '18px' }} >
        <Radio.Group value={dnsProviderId} buttonStyle='solid' onChange={this.handleChangeDnsProvider}>
          {dnsProviders.filter(item => item.visible).map((item, index) => {
            if (item.configJson) {
              return (
                <Radio.Button value={item.id} key={index}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={item.logo} alt='' width={15} height={15} />&nbsp;{item.name}
                  </div>
                </Radio.Button>
              )
            } else {
              return (
                <Radio.Button value={item.id} key={index} disabled>
                  <img src={item.logo} alt='' width={20} height={20} />{item.name}&nbsp;<span style={{ fontSize: '12px', background: '#f50', color: 'white', borderRadius: '10px', lineHeight: '20px', height: '20px', padding: '1px 8px' }}>即将上线</span>
                </Radio.Button>
              )
            }
          })}
          <Radio.Button value={-1} key={-1} style={{top: -3.5, backgroundColor: '#ffe58f'}}>
            <div style={{display: 'flex', alignItems: 'center', height: 30}} className='tucao'>
              <form method="post" action="https://support.qq.com/products/109240/topic-detail/2512/#label=popular" target='_blank' rel='noopener noreferrer'>
                <input type="hidden" name="openid" value={userId}/>
                <input type="hidden" name="nickname" value={maskEmail({email})}/>
                <input type="hidden" name="avatar" value="/assets/images/avatar.png"/>
                <button type='submit' style={{height: 30, lineHeight: '30px'}}>
                  <Icon type="bulb" theme="twoTone"/>&nbsp;我要提需求
                </button>
              </form>
            </div>
          </Radio.Button>
        </Radio.Group>
        <div style={{ marginTop: '20px' }}>
          <Row gutter={16}>
            <Col span={21}>
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder='选择已有DNS授权'
                optionFilterProp='children'
                value={dnsServerId ? dnsServerId : undefined}
                onChange={this.handleChangeDnsServer}
                filterOption={(input, option) => {
                  return option && option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                {dnsServers.rows.filter(item => item.dnsProviderId === dnsProviderId).map((item, index) => {
                  return (<Select.Option value={item.id} key={index}><Tooltip title={item.alias || item.name} placement="bottomLeft">{item.alias || item.name}</Tooltip></Select.Option>)
                })}
              </Select>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div>
            {this.renderDnsServer()}
          </div>
          <Row type='flex' justify='end' align='middle' style={{ marginTop: '10px' }}>
            <Col span={19}>
              <Icon type="info-circle" style={{ color: '#faad14' }} /><a style={{ color: '#faad14' }} href={dnsProvider.configJson.helpUrl} target='_blank' rel='noopener noreferrer'>&nbsp;点击查看如何获取DNS授权参数</a>
            </Col>
            <Col span={5}>
              <Button block onClick={this.handleVerifyDnsServer} loading={serverVerifying}>授权验证{timerSeconds >=0 ? <span>&nbsp;{timerSeconds}&nbsp;S</span> : ''}</Button>
            </Col>
          </Row>
        </div>
        <Divider />
      </div>
    )
  }

  renderDnsAliasAuthModeOfLetsencrypt = () => {
    const { serverVerifying, domains} = this.state
    const { openAcmeChallengeAliasCnameValue } = this.props.configs
    const { userCode } = this.props.me
    const mainDomain = psl.get(domains[0].replace('*.', ''))

    if (!openAcmeChallengeAliasCnameValue || !userCode) {
      return <></>
    }

    const columnsHost = [
      {
        title: '主机记录',
        dataIndex: 'recordHost',
        ellipsis: true,
        render: text => {
          if (!text) {
            return  <>&nbsp;</>
          } else {
            return (
              <div className='record-value'>
                {text}
                <Clipboard className='ant-btn' style={{width: 150}} data-clipboard-text={text.replace(`.${mainDomain}`, '')} onSuccess={this.handleCopyAliasDomainRecordSuccess}>
                  <Icon type='copy'/>&nbsp;点击复制解析记录
                </Clipboard>
              </div>
            )
          }
        }
      }
    ]

    const columnsValue = [
      {
        title: '记录类型',
        dataIndex: 'recordType',
        width: 70
      },
      {
        title: '记录值',
        dataIndex: 'recordValue',
        render: text => {
          return (
            <div className='record-value'>
              {text}
              <Clipboard className='ant-btn' style={{width: 150}} data-clipboard-text={text} onSuccess={this.handleCopyAliasDomainRecordValueSuccess}>
                <Icon type='copy'/>&nbsp;点击复制解析记录值
              </Clipboard>
            </div>
          )
        }
      }
    ]

    const rowsHost = domains.filter(item => item && item.trim().length).map(item => {
      item = item.trim()
      if (item.startsWith('*')) {
        item = item.replace('*.', '')
      }
      return {
        recordHost: `_acme-challenge.${item}`}
    })
    if (rowsHost.length === 0) {
      rowsHost.push({
        recordHost: ''
      })
    }

    const rowsValue = [{
      recordType: 'CNAME',
      recordValue: openAcmeChallengeAliasCnameValue.slice(0, '_acme-challenge.'.length) + userCode + '.' + openAcmeChallengeAliasCnameValue.slice('_acme-challenge.'.length)
    }]

    return (
      <div>
        <div style={{ marginTop: '20px' }} className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={24}>
              <div>
                <Alert message={`请添加以下${rowsHost.filter(item => item.recordHost).length}条记录至您的DNS域名解析记录中`} type="info" showIcon/>
                <Table
                  columns={columnsHost}
                  dataSource={rowsHost}
                  size='small'
                  pagination={false}
                  bordered
                  style={{marginTop: '10px'}}
                  className='record-type-value'
                />
                <p style={{marginTop: '10px'}}>以上域名解析记录的记录类型和记录值统一为：</p>
                <Table
                  columns={columnsValue}
                  dataSource={rowsValue}
                  size='small'
                  pagination={false}
                  bordered
                  style={{marginTop: '-2px'}}
                  className='record-type-value'
                />
                <Alert message="请不要删除以上DNS域名解析记录，否则会导致相关证书生成失败或自动更新失败" type="info" showIcon style={{marginTop: '10px'}}/>
                <Alert message="您的DNS域名解析记录中不能存在与以上主机记录相同的TXT类型解析记录，否则会导致免DNS授权模式失效" type="info" showIcon style={{marginTop: '5px'}}/>
                <Alert message="西部数码、DNS.LA、贝锐等DNS解析服务商由于其提供的CNAME解析不标准，所以暂不支持免DNS授权模式，请使用DNS授权验证模式或者HTTP验证模式申请证书" type="info" showIcon style={{marginTop: '5px'}}/>
                <Alert message="域名的DNSSEC设置可能会导致证书颁发机构对域名所有权的验证失败，如遇多次失败的情况，建议检查域名是否存在DNSSEC设置，如存在可去除域名的DNSSEC相关设置后重试" type="info" showIcon style={{marginTop: '5px'}}/>
              </div>
            </Col>
          </Row>
          <Row type='flex' justify='end'align='middle' style={{ marginTop: '10px' }}>
            <Col span={5} offset={19}>
              <Button block onClick={this.handleCheckDnsCname} loading={serverVerifying}>验证解析记录</Button>
            </Col>
          </Row>
        </div>
        <Divider />
      </div>
    )
  }

  renderDnsAliasAuthModeOfSectigo = () => {
    const { serverVerifying} = this.state
    const { certificateThirdOrder } = this.props.certificate

    if (!certificateThirdOrder) {
      return <></>
    }

    const { orderDetail: { _challengesDetail }} = certificateThirdOrder
    const challengesDetail = _challengesDetail.filter(item => item.type === ACME_CHALLENGE_TYPE.DNS_01)

    const columnsHost = [
      {
        title: '主机记录',
        dataIndex: 'recordHost',
        ellipsis: true,
        render: text => {
          if (!text) {
            return  <>&nbsp;</>
          } else {
            const lodash = '_'
            const placeholder = 'ohttps-lodash-placeholder'
            const mainDomain = psl.get(text.replaceAll(lodash, placeholder)).replaceAll(placeholder, lodash)
            return (
              <div className='record-value'>
                {text}
                <Clipboard className='ant-btn' style={{width: 150}} data-clipboard-text={text.replace(`.${mainDomain}`, '')} onSuccess={this.handleCopyAliasDomainRecordSuccess}>
                  <Icon type='copy'/>&nbsp;点击复制解析记录
                </Clipboard>
              </div>
            )
          }
        }
      }
    ]

    const columnsValue = [
      {
        title: '记录类型',
        dataIndex: 'recordType'
      },
      {
        title: '记录值',
        dataIndex: 'recordValue',
        render: text => {
          return (
            <div className='record-value'>
              {text}
              <Clipboard className='ant-btn' style={{width: 150}} data-clipboard-text={text} onSuccess={this.handleCopyAliasDomainRecordValueSuccess}>
                <Icon type='copy'/>&nbsp;点击复制解析记录值
              </Clipboard>
            </div>
          )
        }
      }
    ]

    const rowsHost = challengesDetail.map(item => {
      return {
        recordHost: item.data.domain
      }
    })
    const rowsValue = [{
      recordType: challengesDetail[0].data.type,
      recordValue: challengesDetail[0].data.value
    }]

    return (
      <div>
        <div style={{ marginTop: '20px' }} className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={24}>
              <div>
                <Alert message={`请添加以下${rowsHost.filter(item => item.recordHost).length}条记录至您的DNS域名解析记录中`} type="info" showIcon/>
                <Table
                  columns={columnsHost}
                  dataSource={rowsHost}
                  size='small'
                  pagination={false}
                  bordered
                  style={{marginTop: '10px'}}
                  className='record-type-value'
                />
                <p style={{marginTop: '10px'}}>以上域名解析记录的记录类型和记录值统一为：</p>
                <Table
                  columns={columnsValue}
                  dataSource={rowsValue}
                  size='small'
                  pagination={false}
                  bordered
                  style={{marginTop: '-2px'}}
                  className='record-type-value'
                />
                <Alert message="请不要删除以上DNS域名解析记录，否则会导致相关证书生成失败或自动更新失败" type="info" showIcon style={{marginTop: '10px'}}/>
                <Alert message="免DNS授权模式暂不支持Sectigo证书的自动更新，如需证书的自动更新功能请使用DNS授权模式" type="info" showIcon style={{marginTop: '10px'}}/>
              </div>
            </Col>
          </Row>
          <Row type='flex' justify='end'align='middle' style={{ marginTop: '10px' }}>
            <Col span={5} offset={19}>
              <Button block onClick={this.handleCheckDnsCname} loading={serverVerifying}>验证解析记录</Button>
            </Col>
          </Row>
        </div>
        <Divider />
      </div>
    )
  }

  renderHttpAuthMode = () => {
    const { serverVerifying, domains, certificateBrand} = this.state

    return (
      <div>
        <div style={{ marginTop: '20px' }} className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={24}>
              <HttpAuthModeServerProxySettings domains={domains} certificateBrand={certificateBrand}/>
            </Col>
          </Row>
          <Row type='flex' justify='end'align='middle' style={{ marginTop: '10px' }}>
            <Col span={5} offset={19}>
              <Button block onClick={this.handleCheckHttpProxy} loading={serverVerifying}>验证服务端配置</Button>
            </Col>
          </Row>
        </div>
        <Divider />
      </div>
    )
  }

  renderHttpManAuthMode = () => {
    const { serverVerifying} = this.state

    return (
      <div>
        <div style={{ marginTop: '20px' }} className='dns-records'>
          <Row type='flex' justify='start' gutter={16} align='middle' style={{ marginTop: '10px' }}>
            <Col span={24}>
              <HttpManAuthModeServerFileSettings/>
            </Col>
          </Row>
          <Row type='flex' justify='end'align='middle' style={{ marginTop: '10px' }}>
            <Col span={5} offset={19}>
              <Button block onClick={this.handleCheckHttpFile} loading={serverVerifying}>验证服务端文件</Button>
            </Col>
          </Row>
        </div>
        <Divider />
      </div>
    )
  }

  renderCreateCertificateVersionModal = () => {
    const {certificateDetail, createCertificateVersionModalVisible} = this.state
    if (!createCertificateVersionModalVisible) {
      return
    }

    return (
      <CreateCertificateVersionModal
        isForRetry={true}
        certificateDetail={certificateDetail}
        onOk={this.handelCreateCertificateVersionOk}
        onCancel={this.handelCreateCertificateVersionCancel}
      />
    )
  }

  renderDnsAliasAuthMode = () => {
    switch (this.state.certificateBrand) {
      case CERTIFICATE_BRAND.LETSENCRYPT:
        return this.renderDnsAliasAuthModeOfLetsencrypt()
        case CERTIFICATE_BRAND.SECTIGO:
        return this.renderDnsAliasAuthModeOfSectigo()
      default:
        return
    }
  }

  renderAuthMode = () => {
    switch (this.state.authMode) {
      case CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE:
        return this.renderDnsAliasAuthMode()
      case CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE:
        return this.renderDnsApiAuthMode()
      case CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE:
        return this.renderHttpAuthMode()
      case CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE:
        return this.renderHttpManAuthMode()
      default:
        return
    }
  }

  renderSteps = () => {
    return (
      <Steps
        type="navigation"
        size="small"
        current={this.state.currentStep}
        onChange={this.onChange}
        style={{
          marginBottom: 24,
          boxShadow: '0px -1px 0 0 #e8e8e8 inset',
        }}
      >
        <Steps.Step status={this.getStepStatus({stepIndex: 0})} title="添加域名/IP" />
        <Steps.Step status={this.getStepStatus({stepIndex: 1})} title="验证域名/IP" />
        <Steps.Step status={this.getStepStatus({stepIndex: 2})} title="申请证书" />
      </Steps>
    )
  }

  renderAddDomainsStep = () => {
    return (
      <div className='content'>
        {this.renderSteps()}
        <p>选择证书类型，添加证书申请所需的域名或IP（仅Sectigo支持IP证书）</p>
        <Divider/>
        <div>
          <Radio.Group value={this.state.certificateBrand} buttonStyle='solid' onChange={this.handleChangeCertificateBrand} style={{display: 'flex'}}>
            <Radio.Button value={CERTIFICATE_BRAND.SECTIGO} style={{height: 50, width: 150}}>
              <div style={{width: 140, display: 'flex', alignItems: 'baseline', flexDirection: 'column'}}>
                <div style={{height: 25}}><img src={sectigoImg} height={14} alt="Sectigo"/></div>
                <span style={{transform: 'scale(0.8) translateX(-18px) translateY(-5px)', height: 14}}>专业版证书·有效期365天</span>
              </div>
            </Radio.Button>
            <Radio.Button value={CERTIFICATE_BRAND.LETSENCRYPT} style={{height: 50, width: 150}}>
              <div style={{width: 140, display: 'flex', alignItems: 'baseline', flexDirection: 'column'}}>
                <img src={letsenryptImg} height={25} alt="Let's encrypt"/>
                <span style={{transform: 'scale(0.8) translateX(-14px) translateY(-5px)', height: 14}}>免费版证书·有效期90天</span>
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Radio.Group value={this.state.certificateType} buttonStyle='solid' onChange={this.handleChangeCertificateType}>
            <Radio.Button value='WILDCARD'><span style={{position: 'relative'}}>泛域名证书&emsp;&emsp;&emsp;<p style={{position: 'absolute', top: '-6px', right: '-12px', transform: 'scale(0.8)', background: '#f50', color: 'white', height: '19px', lineHeight: '16px', padding: '2px 3px'}}>推荐</p></span></Radio.Button>
            <Radio.Button value='SINGLE'>单域名证书</Radio.Button>
            <Radio.Button value='MULTI'>多域名证书</Radio.Button>
            {this.state.certificateBrand === CERTIFICATE_BRAND.SECTIGO ? <Radio.Button value='IP'>IP证书</Radio.Button> : ''}
          </Radio.Group>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Row gutter={16}>
            <Col span={24}>
              {this.renderCertificatesInput()}
            </Col>
          </Row>
          <div style={{textAlign: 'right', marginTop: '20px'}}>
            <CertificatePrice
              certificateBrand={this.state.certificateBrand}
              certificateType={this.state.certificateType}
              certificateDomains={this.state.domains}
              isFirstVersion={true}
              isAutoUpdate={false}
            />
          </div>
          <Row style={{ marginTop: '20px' }}>
            <Col span={5} offset={0}>
              <Button block onClick={this.handleGoMonitorDashboard.bind(this)}>回到主页</Button>
            </Col>
            <Col span={5} offset={14}>
              <Button block type='primary' onClick={this.handleCompleteAddDomains.bind(this)}>下一步</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  checkAuthModeDisabled = (authMode) => {
    const {domains, certificateType, certificateBrand} = this.state

    const hasWildcardDomain = domains.some(item => item.startsWith('*.'))
    if (hasWildcardDomain && authMode !== CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE && authMode !== CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE) {
      return true
    }

    if (certificateType === CERTIFICATE_TYPE.IP && authMode !== CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE && authMode !== CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) {
      return true
    }

    if (certificateBrand === CERTIFICATE_BRAND.LETSENCRYPT && authMode === CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) {
      return true
    }

    return false
  }

  renderAddDnsRecordsStep = () => {
    const authModeDisabledStyle = {
      color: 'rgba(0, 0, 0, 0.25)',
      backgroundColor: '#f5f5f5',
      borderColor: '#d9d9d9',
      cursor: 'not-allowed'
    }

    return (
      <div className='content'>
        {this.renderSteps()}
        <p>选择验证域名/IP所有权方式</p>
        <Divider/>
        <Radio.Group value={this.state.authMode} buttonStyle='solid' onChange={this.handleChangeAuthMode}>
          <Radio.Button value={CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE} style={this.checkAuthModeDisabled(CERTIFICATE_AUTH_MODE.DNS_ALIAS_AUTH_MODE) ? authModeDisabledStyle : {}}>免DNS授权验证模式</Radio.Button>
          <Radio.Button value={CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE} style={this.checkAuthModeDisabled(CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE) ? authModeDisabledStyle : {}}>DNS授权验证模式</Radio.Button>
          <Radio.Button value={CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE} style={this.checkAuthModeDisabled(CERTIFICATE_AUTH_MODE.HTTP_AUTH_MODE) ? authModeDisabledStyle : {}}>HTTP代理验证模式</Radio.Button>
          <Radio.Button value={CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE} style={this.checkAuthModeDisabled(CERTIFICATE_AUTH_MODE.HTTP_MAN_AUTH_MODE) ? authModeDisabledStyle : {}}>HTTP文件验证模式</Radio.Button>
        </Radio.Group>
        <span><Icon type="info-circle" style={{ color: '#faad14', marginLeft: 10}} /><a style={{ color: '#faad14'}} href={'/docs/start'} target='_blank' rel='noopener noreferrer'>&nbsp;点击查看如何选择验证域名/IP所有权方式</a></span>
        {this.renderAuthMode()}
          <Row style={{ marginTop: '20px' }}>
            <Col span={5} offset={0}>
              <Button block onClick={this.handleBackToAddDomains.bind(this)}>上一步</Button>
            </Col>
            <Col span={5} offset={14}>
              <Button block type='primary' onClick={this.handleCreateCertificate.bind(this)}>创建证书</Button>
            </Col>
          </Row>
          <Drawer
              title="余额充值"
              placement="right"
              closable={false}
              width = {400}
              onClose={this.handleChargeDrawerClose}
              visible={this.state.chargeDrawerVisible}
            >
              <Charge />
          </Drawer>
      </div>
    )
  }

  renderCreateCertificateStep = () => {
    const {timerSeconds, certificateVersion} = this.state

    if (!certificateVersion || certificateVersion.status === CERTIFICATE_VERSION_STATUS.QUEUING || certificateVersion.status === CERTIFICATE_VERSION_STATUS.GENERATING) {
      return (
        <div className='content'>
          {this.renderSteps()}
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Icon type="loading" />
                <span style={{paddingTop: '2px'}}>&emsp;{timerSeconds > 0 ? <span>{timerSeconds} S</span> : '0 S'}</span>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                证书申请中，预计需要 3 ～ 15 分钟，请您耐心等待
              </div>
            </Col>
          </Row>
        </div>
      )
    } else if (certificateVersion.status === CERTIFICATE_VERSION_STATUS.ERROR) {
      return (
        <div className='content'>
          {this.renderSteps()}
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#f50'}}>
                <Icon type="exclamation-circle" />
                <span style={{paddingTop: '2px'}}>&emsp;证书申请失败</span>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={24}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#f50'}}>
                {certificateVersion.error}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={5} offset={9}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button type='primary' block onClick={this.handleCreateCertifiateVersion.bind(this)}>重新申请</Button>
              </div>
            </Col>
          </Row>
          {this.renderCreateCertificateVersionModal()}
        </div>
      )
    } else {
      return (
        <div className='content'>
          {this.renderSteps()}
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <CertificateVersionDetail certificateVersionDetail={certificateVersion} />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col span={24} style={{textAlign: 'right'}}>
              <Button type='link' href='/docs/certformat' target='_blank' rel='noopener noreferrer'>需要其他格式的证书文件？点击此出了解如何转换证书格式</Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={5} offset={19}>
              <Button block type='primary' onClick={this.handleGoMonitorCertificates.bind(this)}>管理证书</Button>
            </Col>
          </Row>
        </div>
      )
    }
  }

  render() {
    switch (this.state.currentStep) {
      case 0:
        return this.renderAddDomainsStep()
      case 1:
        return this.renderAddDnsRecordsStep()
      case 2:
        return this.renderCreateCertificateStep()
      default:
        return <></>
    }
  }
}

CreateCertificate = connect((state) => {
  return {
    configs: state.common.configs,
    me: state.auth.me,
    dnsProviders: state.dns.provider.dnsProviders,
    dnsServers: state.dns.server.dnsServers,
    certificate: state.certificate.certificate
  }
})(CreateCertificate)

export default CreateCertificate
